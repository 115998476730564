export enum OauthProvider {
  GOOGLE = 'google_oauth2',
  APPLE = 'apple',
  MICROSOFT = 'azure_activedirectory_v2',
  FACEBOOK = 'facebook',
  EMAIL = 'email',
}

export const paginationBatchSize = 15;

export const maxPaymentMethods = 100;

export const daysOfTheMonth = [
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  '13th',
  '14th',
  '15th',
  '16th',
  '17th',
  '18th',
  '19th',
  '20th',
  '21st',
  '22nd',
  '23rd',
  '24th',
  '25th',
  '26th',
  '27th',
  '28th',
];

export const permissionsPageNames = {
  DOCUMENTS: 'Documents',
  CONTACT: 'Contact',
  MAINTENANCE: 'Maintenance',
  CREATE_MAINTENANCE_REQUESTS: 'Create Maintenance Requests',
  LIST_MAINTENANCE_REQUESTS: 'List Maintenance Requests',
  PAYMENTS: 'Pay Bills',
  PREPAYMENTS: 'Prepayments',
};

export const COLORS = [
  '#2B67FF',
  '#1BACFD',
  '#FF3665',
  '#F3CE42',
  '#FF8C22',
  '#40CE6E',
  '#1DBAB1',
  '#B852DA',
  '#F959CC',
  '#6981B0',
  '#FC714B',
  '#86C843',
  '#7367FF',
  '#BE438D',
  '#00CEFB',
  '#FB3636',
  '#D03E61',
  '#516379',
  /*'#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#A569BD',
  '#5DADE2',
  '#58D68D',
  '#F5B041',
  '#EB984E',
  '#DC7633',
  '#6E2C00',
  '#1B4F72',
  '#2ECC71',
  '#9B59B6',
  '#34495E',
  '#D35400',
  '#C0392B',
  '#7D3C98',*/
];

export const compactWidth = 300;
